@import '../../styles/shared.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $light-gray5;
}

.container {
  position: relative;
  flex-grow: 1;
  overflow-y: scroll;

  // &::before {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   display: block;
  //   width: 100%;
  //   max-width: get($v-container-width);
  //   height: 100%;
  //   background-color: $white;
  //   box-shadow: $pt-elevation-shadow-1;
  //   transform: translate(-50%, -50%);
  //   content: '';
  // }
}
