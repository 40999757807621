@import '../../styles/shared.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2*get($v-spacing, large);
  padding-bottom: get($v-spacing, large);
}
