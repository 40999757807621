@import '../../../styles/shared';

.list {
  display: flex;
  flex-direction: column;
  gap: get($v-spacing);
}

.actions {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, auto);
  gap: get($v-spacing);
  align-items: start;
  justify-content: space-between;
  margin-top: get($v-spacing, large);

  &__add {
    display: flex;
    flex-direction: column;
    gap: get($v-spacing, small);

    @include media(">sm") {
      flex-direction: row;
    }
  }
}
