@import '../../styles/shared';

.sentence {
  display: flex;
  flex-wrap: wrap;
  gap: get($v-spacing, small);
  align-items: center;
  justify-content: center;
  font-size: get($v-font-size-relative, much-increased);
  word-break: break-word;
}

.sentencePart {
  display: flex;
  flex-wrap: wrap;
  gap: get($v-spacing, small);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.amountInput {
  width: 6em;
  padding-right: 1px;

  :global(.bp3-input-group) {
    flex-shrink: 1;
  }

  input {
    text-align: center;
  }
}

.subjectInput {
  flex-grow: 1;

  & > input {
    width: auto !important;
  }
}

.detailsToggle {
  display: block !important;
  margin-left: auto;
}

.error {
  margin: 0;
  text-align: center;
}

.datepickerToggle {
  min-width: 7em !important;
}
