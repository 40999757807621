@import '../../styles/_shared.scss';

$_mobile-bg-color: $white;
$_mobile-menu-bg-color: $white;
$_transition-duration: get($v-duration, long);
$_transition-easing: cubic-bezier(0.23, 1, 0.32, 1);
$_hamburger-size: 18px;
$_hamburger-thickness: 2px;
$_hamburger-button-notext-width: 40px;

.wrapper {
  position: relative;
  padding: 0 !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: get($v-shadow, large);
    transition: opacity get($v-duration) ease;
    content: '';
  }

  &--fixed {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
  }

  &--transparent:not(&--active) {
    .localeSwitch :global(.bp3-button) {
      &,
      &:hover {
        color: white;
      }
    }

    &::before {
      opacity: 0;
    }
  }

  @include media(">md") {
    padding-right: $v-scrollbar-width !important;

    &--fixed {
      padding-right: 0 !important;
    }
  }
}

.content {
  position: relative;
  height: 100%;
}

.hamburger {
  position: absolute;
  width: 0;

  @include media(">md") {
    display: none;
  }
}

.hamburgerBar {
  position: absolute;
  top: calc(50% - #{$_hamburger-thickness/2});
  right: get($v-spacing);
  display: block;
  width: $_hamburger-size;
  height: $_hamburger-thickness;
  margin: 0 !important;
  background-color: currentColor;
  transition: transform ease $_transition-duration, opacity ease $_transition-duration;
  pointer-events: none;

  &--one {
    transform: translateY(-($_hamburger-size - 3 * $_hamburger-thickness)/2);
  }

  &--three {
    transform: translateY(($_hamburger-size - 3 * $_hamburger-thickness)/2);
  }
}

.hamburgerButton {
  position: absolute;
  top: 50%;
  right: get($v-spacing);
  padding-right: $_hamburger-size + get($v-spacing) + $pt-grid-size !important;
  font-weight: get($v-font-weight, bold);
  text-transform: uppercase;
  transform: translateY(-50%);

  &--noText {
    padding-right: ($_hamburger-button-notext-width - $_hamburger-size)/2 !important;
    padding-left: ($_hamburger-button-notext-width - $_hamburger-size)/2 !important;

    .hamburgerBar {
      right: ($_hamburger-button-notext-width - $_hamburger-size)/4 !important;
    }
  }

  .wrapperActive & {
    .hamburgerBar {
      &--one {
        transform: rotate(45deg);
      }

      &--two {
        transform: rotate(180deg);
        opacity: 0;
      }

      &--three {
        transform: rotate(-45deg);
      }
    }
  }

  @include media(">md") {
    display: none !important;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  display: block;
  background-color: rgba(0, 0, 0, get($v-opacity, tiny));
  backdrop-filter: blur(5px);
  transition: opacity $_transition-duration ease-in-out;

  .wrapper:not(.wrapper--active) & {
    opacity: 0;
    transition-timing-function: $_transition-easing;
    pointer-events: none;
  }

  @include media(">md") {
    display: none;
  }
}

.primary {
  margin-right: $_hamburger-button-notext-width;

  @include media(">md") {
    margin-right: 0;
  }
}

.secondary {
  $_spacing: get($v-spacing);

  @include media("<md") {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    gap: $_spacing;
    width: 100%;
    padding: $_spacing;
    overflow: auto;
    background-color: $_mobile-menu-bg-color;
    transition: transform $_transition-duration $_transition-easing;

    .group {
      display: flex;
      flex-direction: column;
      gap: $_spacing;
      float: none;
      height: auto;
    }

    .divider {
      display: none;
    }

    .wrapper--active & {
      box-shadow: $pt-elevation-shadow-1;
      transform: translateY(calc(100% + #{$pt-navbar-height} - 1px));
      transition: transform get($v-duration) $_transition-easing;
    }
  }
}

.navLink {
  transition: opacity get($v-duration) !important;

  &:hover {
    opacity: get($v-opacity, high);
  }
}

.navLinkCurrent {
  opacity: get($v-opacity, high);
}

.profile {
  display: inline-flex;
  gap: get($v-spacing, small);
}
