@import '../../styles/shared.scss';

.primaryContent {
  flex: 1 0 100%;

  @include media(">sm") {
    flex-shrink: 1;
  }
}

.secondaryContent {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  gap: get($v-spacing);
  align-items: flex-start;
  align-items: baseline;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 1rem;
  white-space: nowrap;

  @include media(">sm") {
    flex-wrap: nowrap;
  }
}

.title {
  display: flex;
  flex-wrap: wrap;
  gap: get($v-spacing);
  align-items: baseline;
  margin-top: 1em;
  margin-bottom: 1em;

  &--center {
    justify-content: center;

    .primaryContent {
      text-align: center;
    }
  }

  &--condensed {
    gap: get($v-spacing, small);
    margin-top: 0.5em;
    margin-bottom: 0.75em;
  }

  @include media(">sm") {
    flex-wrap: nowrap;

    &--wrapped {
      flex-wrap: wrap;
    }
  }
}
