$pt-intent-primary: $gold5;
$pt-link-color: $gold3;
$pt-dark-link-color: $gold4;
$pt-text-selection-color: rgba($gold5, 0.4);
$button-border-width: 1px;
$button-box-shadow:
  inset 0 0 0 $button-border-width rgba($black, 0.15),
  inset 0 (-$button-border-width) 0 rgba($black, 0.07);
$button-box-shadow-active:
  inset 0 0 0 $button-border-width rgba($black, get($v-opacity, low)),
  inset 0 1px 2px rgba($black, get($v-opacity, low));
$button-intent-box-shadow: $button-box-shadow;
$button-intent-box-shadow-active: $button-box-shadow-active;

$button-intents: (
  "primary": ($pt-intent-primary, $gold2, $gold1),
  "success": ($pt-intent-success, $green2, $green1),
  "warning": ($pt-intent-warning, $orange2, $orange1),
  "danger": ($pt-intent-danger, $red2, $red1)
);
$pt-intent-colors: (
  "primary": $pt-intent-primary,
  "success": $pt-intent-success,
  "warning": $pt-intent-warning,
  "danger" : $pt-intent-danger,
);
$pt-intent-text-colors: (
  "primary": $pt-link-color,
  "success": $pt-intent-success,
  "warning": $pt-intent-warning,
  "danger" : $pt-intent-danger,
);
