@import '../../styles/_shared.scss';

$_bg-color: $white;

.container,
.navWrapper {
  display: flex;
  flex-direction: column;
  gap: get($v-spacing);
  align-items: center;
}

.wrapper {
  position: relative;
  height: $v-footer-height;
  background-color: $_bg-color;
  border-top: 1px solid $vc-border;

  @include media(">sm") {
    padding-right: $v-scrollbar-width;
  }
}

.container {
  @include media(">sm") {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.part {
  text-align: center;

  @include media(">sm") {
    text-align: left;
  }
}

.textSecondary {
  opacity: get($v-opacity, medium);
}

.navLink {
  transition: opacity get($v-duration) !important;

  &:hover {
    opacity: get($v-opacity, high);
  }
}

.navLinkCurrent {
  opacity: get($v-opacity, high);
}
