.#{$ns}-button {
  transition: background-color 0.2s;

  &.#{$ns}-intent-primary {
    &,
    &:hover {
      color: $pt-text-color;
    }
  }
}

.#{$ns}-button,
.#{$ns}-input {
  font-family: inherit;
}

.#{$ns}-datepicker,
.#{$ns}-daterangepicker {
  .DayPicker-Day {
    &.DayPicker-Day--selected {
      background-color: $gold4;

      &:hover {
        background-color: $gold3;
      }

      &:active {
        background-color: $gold2;
      }

      .#{$ns}-dark & {
        background-color: $gold4;

        &:hover {
          background-color: $gold3;
        }

        &:active {
          background-color: $gold2;
        }
      }
    }
  }
}

.#{$ns}-daterangepicker {
  .DayPicker-Day {
    &--hovered-range {
      color: $gold3;
    }

    &--selected-range {
      color: $gold3;

      &:hover {
        color: $gold3;
      }
    }

    .#{$ns}-dark & {
      &--selected {
        background-color: $gold4;
      }

      &--hovered-range {
        color: $light-gray5;
      }

      &--selected-range {
        color: $light-gray5;
      }
    }
  }
}

.#{$ns}-control.#{$ns}-checkbox input:checked~.#{$ns}-control-indicator:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

$pt-outline-color: rgba($gold4, get($v-opacity, medium));

//had to be redefined due to $pt-outline-color not being !default
@mixin focus-outline-recolored($offset: 2px) {
  outline: $pt-outline-color auto 2px;
  outline-offset: $offset;
  -moz-outline-radius: 6px;
}

:focus {
  @include focus-outline-recolored();
}
