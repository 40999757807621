@import '../../styles/_shared.scss';

.wrapper {
  display: flex !important;
  margin: get($v-spacing, large) auto;

  &:last-child {
    margin-bottom: 0;
  }
}
