@import '../../../styles/shared';

.wrapper {
  position: relative;
}

.input {
  height: 0;
  visibility: hidden;
}

.pseudoInput {
  flex: 1 1 100%;
}

.inputText {
  &::after {
    content: attr(data-button-text) !important;
  }
}

.previewWrapper {
  max-width: 100%;
}

.preview,
.placeholder {
  max-width: 100%;
  margin: 0 auto get($v-spacing, small);
}

.placeholder {
  position: relative;
  min-height: get($v-avatar-size);
  color: $light-gray5;
  background-color: $vc-placeholder-background;
}

.placeholderIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
}

.inputWrapper {
  :global(.bp3-form-content) {
    display: flex;
    gap: get($v-spacing, small);
    align-items: center;
  }
}
