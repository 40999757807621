.wrapper {
  @keyframes appear {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100% { opacity: 1; }
  }

  animation: appear 1s forwards;
}

.dot {
  animation-duration: 4s;
  animation-delay: 1s;
  animation-iteration-count: infinite;

  &--first {
    @keyframes blink1 {
      0% { opacity: 0; }
      20% { opacity: 0; }
      80% { opacity: 1; }
    }

    animation-name: blink1;
  }

  &--second {
    @keyframes blink2 {
      0% { opacity: 0; }
      40% { opacity: 0; }
      80% { opacity: 1; }
    }

    animation-name: blink2;
  }

  &--third {
    @keyframes blink3 {
      0% { opacity: 0; }
      60% { opacity: 0; }
      80% { opacity: 1; }
    }

    animation-name: blink3;
  }
}
