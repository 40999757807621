.transitionGroupItem-enter {
  transform: scaleY(0);
  opacity: 0;
}

.transitionGroupItem-enter-active {
  transform: scaleY(1);
  opacity: 1;
  transition:
    opacity get($v-duration) ease-out,
    transform get($v-duration) cubic-bezier(0.72, -0.21, 0.39, 1.24);
}

.transitionGroupItem-exit {
  transform: scaleY(1);
  opacity: 1;
}

.transitionGroupItem-exit-active {
  transform: scaleY(0);
  opacity: 0;
  transition:
    opacity get($v-duration) ease-out,
    transform get($v-duration) cubic-bezier(0.72, -0.21, 0.39, 1.24);
}
