@import '../../../../../../styles/_shared.scss';

.expenseItem {
  &__price {
    display: inline-block;
    margin-right: get($v-spacing, small);
    font-weight: get($v-font-weight, bold);
    font-size: get($v-font-size-relative, increased);
  }

  &__subject {
    font-size: get($v-font-size-relative, increased);
  }

  &__actions {
    display: flex;
    gap: get($v-spacing, small);
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $vc-border;
  border-bottom: 1px solid $vc-border;
}

.summaryList {
  @include sentence-list;

  margin-top: get($v-spacing);
}
