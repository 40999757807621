@import '../../../../../styles/_shared.scss';

$_landing-text-color: $indigo1;

.page {
  color: $_landing-text-color;
}

.hero,
.cta {
  color: $white;
  background-color: #6c6fc2; //from illustrations

  &__action {
    color: $_landing-text-color !important;
    font-weight: get($v-font-weight, bold);
  }
}

.hero {
  position: relative;
  padding-top: $pt-navbar-height+get($v-spacing, large);
  padding-bottom: get($v-spacing, xlarge);
  background: linear-gradient(180deg, #6c6fc2, #9a9de5); //from illustrations

  &__content {
    display: grid;
    grid-template-areas: 'illustration''text''action';
    grid-template-rows: auto min-content auto;
    grid-template-columns: 1fr;
    gap: get($v-spacing);
    justify-items: center;
    text-align: center;

    @include media(">sm") {
      grid-template-areas: 'text illustration''action illustration';
      grid-template-rows: repeat(2, auto);
      grid-template-columns: max-content auto;
      text-align: right;
    }
  }

  &__text {
    grid-area: text;
    align-self: center;
    margin: 0;
    font-size: clamp(1.5rem, 5vw, 2rem);

    @include media(">sm") {
      align-self: end;
      justify-self: end;
      white-space: nowrap;
    }
  }

  &__illustration {
    grid-area: illustration;
    align-self: end;
    width: 60vmin;
    height: 60vmin;

    @include media(">sm") {
      align-self: center;
      justify-self: start;
      width: auto;
      max-width: 40vw;
      height: auto;
      max-height: 80vh;
      margin: 0 get($v-spacing);
      margin-right: auto;
    }

    @include media(">md") {
      max-width: none;
      max-height: none;
    }
  }

  &__action {
    grid-area: action;
    align-self: start;

    @include media(">sm") {
      align-self: start;
      justify-self: end;
    }
  }
}

.depiction {
  $_r: &;
  $_text-column-width: 20em;

  padding: get($v-spacing) 0;

  &:first-child {
    padding-top: get($v-spacing, large);
  }

  &:last-child {
    padding-bottom: get($v-spacing, large);
  }

  @include media(">sm") {
    padding: get($v-spacing, large) 0;

    &:first-child {
      padding-top: get($v-spacing, xlarge);
    }

    &:last-child {
      padding-bottom: get($v-spacing, xlarge);
    }
  }

  &__content {
    display: grid;
    grid-template-areas: 'illustration''text';
    grid-template-rows: auto min-content;
    grid-template-columns: 1fr;
    gap: get($v-spacing);
    justify-items: center;
    text-align: center;

    @include media(">sm") {
      grid-gap: get($v-spacing, large);
      grid-template-areas: 'illustration text';
      grid-template-rows: auto;
      grid-template-columns: auto $_text-column-width;
    }
  }

  &__text {
    grid-area: text;
    padding: get($v-spacing) 0 0;

    strong {
      white-space: nowrap;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    br {
      display: block;
      margin-top: 0.5em;
      content: ' ';
    }

    @include media(">sm") {
      align-self: center;
      padding: get($v-spacing, large) 0;
    }
  }

  &__illustration {
    grid-area: illustration;
    max-width: 24em;
    max-height: 50vh;

    @include media(">sm") {
      align-self: center;
      margin: 0 0 0 auto;
    }

    @include media(">md") {
      max-width: none;
      max-height: 30em;
      margin-left: -(get($v-breakpoint, md) - get($v-container-width, sm))/2;
    }
  }

  &__title {
    margin: 0 0 0.5em;
    font-size: inherit;
  }

  &--inversed {
    @include media(">sm") {
      #{$_r}__content {
        grid-template-areas: 'text illustration';
        grid-template-columns: $_text-column-width auto;
      }

      #{$_r}__illustration {
        margin: 0 auto 0 0;
      }
    }

    @include media(">md") {
      #{$_r}__illustration {
        margin-right: -(get($v-breakpoint, md) - get($v-container-width, sm))/2;
        margin-left: 0;
      }
    }
  }
}

.cta {
  padding-top: get($v-spacing, xlarge);
  padding-bottom: get($v-spacing, xlarge);
  font-weight: get($v-font-weight, bold);
  font-size: get($v-font-size-relative, much-increased);
  text-align: center;
  background: linear-gradient(0deg, #6c6fc2, #9a9de5); //from illustrations

  &__text {
    margin: 0;
  }

  &__action {
    margin-top: get($v-spacing, large);
  }
}
