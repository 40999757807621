@import '../../styles/_shared.scss';

$_shadow-size: 10px;
$_content-size: 70px;

.wrapper {
  position: sticky;
  bottom: 0;

  &::before {
    position: sticky;
    top: calc(100vh - #{$_content-size} - #{$_shadow-size} - #{$v-navbar-height});
    z-index: -2;
    display: block;
    width: 100%;
    height: $_shadow-size;
    background: linear-gradient(0deg, rgba(0, 0, 0, get($v-opacity, tiny)), transparent);
    content: '';
  }

  @include media(">sm") {
    &::before {
      top: calc(100vh - #{$_content-size} - #{$_shadow-size} - #{$v-navbar-height} - #{$v-footer-height});
    }
  }
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: $_content-size;
  padding: get($v-spacing);
  background-color: $vc-background;
}
