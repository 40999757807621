$black: #111418;

$dark-gray1: #1c2127;
$dark-gray2: #252a31;
$dark-gray3: #2f343c;
$dark-gray4: #383e47;
$dark-gray5: #404854;

$gray1: #5f6b7c;
$gray2: #738091;
$gray3: #8f99a8;
$gray4: #abb3bf;
$gray5: #c5cbd3;

$light-gray1: #d3d8de;
$light-gray2: #dce0e5;
$light-gray3: #e5e8eb;
$light-gray4: #edeff2;
$light-gray5: #f6f7f9;

$white: #fff;

// Core colors

$blue1: #184a90;
$blue2: #215db0;
$blue3: #2d72d2;
$blue4: #4c90f0;
$blue5: #8abbff;

$green1: #165a36;
$green2: #1c6e42;
$green3: #238551;
$green4: #32a467;
$green5: #72ca9b;

$orange1: #77450d;
$orange2: #935610;
$orange3: #c87619;
$orange4: #ec9a3c;
$orange5: #fbb360;

$red1: #8e292c;
$red2: #ac2f33;
$red3: #cd4246;
$red4: #e76a6e;
$red5: #fa999c;

// Extended colors

$vermilion1: #96290d;
$vermilion2: #b83211;
$vermilion3: #d33d17;
$vermilion4: #eb6847;
$vermilion5: #ff9980;

$rose1: #a82255;
$rose2: #c22762;
$rose3: #db2c6f;
$rose4: #f5498b;
$rose5: #ff66a1;

$violet1: #5c255c;
$violet2: #7c327c;
$violet3: #9d3f9d;
$violet4: #bd6bbd;
$violet5: #d69fd6;

$indigo1: #5642a6;
$indigo2: #634dbf;
$indigo3: #7961db;
$indigo4: #9881f3;
$indigo5: #bdadff;

$cerulean1: #1f4b99;
$cerulean2: #2458b3;
$cerulean3: #2965cc;
$cerulean4: #4580e6;
$cerulean5: #669eff;

$turquoise1: #004d46;
$turquoise2: #007067;
$turquoise3: #00a396;
$turquoise4: #13c9ba;
$turquoise5: #7ae1d8;

$forest1: #1d7324;
$forest2: #238c2c;
$forest3: #29a634;
$forest4: #43bf4d;
$forest5: #62d96b;

$lime1: #43501b;
$lime2: #5a701a;
$lime3: #8eb125;
$lime4: #b6d94c;
$lime5: #d4f17e;

$gold1: #5c4405;
$gold2: #866103;
$gold3: #d1980b;
$gold4: #f0b726;
$gold5: #fbd065;

$sepia1: #5e4123;
$sepia2: #7a542e;
$sepia3: #946638;
$sepia4: #af855a;
$sepia5: #d0b090;
