@import '../../../../styles/shared';

@each $intent, $color in $v-intent-colors {
  :global(.bp3-intent-#{$intent}) {
    .selectWrapper {
      :global(.bp3-input) {
        box-shadow: input-transition-shadow($color, true), $input-box-shadow-focus;
      }
    }
  }
}
