$v-direction-short-names: (
  top: 't',
  right: 'r',
  bottom: 'b',
  left: 'l'
);

$v-spacing: (
  default: $pt-grid-size * 1.5,
  xlarge: $pt-grid-size * 4.5,
  large: $pt-grid-size * 3,
  small: $pt-grid-size,
  tiny: $pt-grid-size / 2,
  zero: 0,
);

$v-duration: (
  default: 200ms,
  short: 100ms,
  long: 500ms,
);

$v-breakpoint: (
  sm: 500px,
  md: 900px,
  lg: 1200px,
);

$v-opacity: (
  default: 1,
  tiny: 0.1,
  low: 0.2,
  medium: 0.5,
  high: 0.7,
);

$v-shadow: (
  large: 0 0 20px 0 rgba(0, 0, 0, get($v-opacity, low)),
);

$v-font-weight: (
  default: 400,
  bold: 700,
);

$v-font-size-relative: (
  default: 1em,
  increased: 1.2em,
  much-increased: 1.5em,
  decreased: 0.8em,
);

$v-avatar-size: (
  default: 96px,
  small: $pt-input-height,
  medium: 48px,
  large: 160px,
);

$v-rating-size: (
  default: $pt-input-height,
  small: 16px,
);

$v-container-width: (
  default: get($v-breakpoint, lg),
  sm: 700px,
  xs: 370px,
);

$v-container-padding: (
  default: get($v-spacing),
  large: get($v-spacing, large),
);

$v-navbar-height: 50px;
$v-footer-height: 60px;

$v-scrollbar-width: 24px;

$vc-border: $light-gray2;
$vc-placeholder-background: $vc-border;
$vc-placeholder-foreground: $light-gray5;
$vc-background: white;
$vc-background-secondary: $light-gray4;

$v-intent-colors: (
  primary: $pt-intent-primary,
  success: $pt-intent-success,
  warning: $pt-intent-warning,
  danger: $pt-intent-danger,
);
