@import '../../styles/_shared.scss';

.list {
  @include unstyled-list;

  display: flex;
  flex-direction: column;
  gap: get($v_spacing, large);

  &--condensed {
    gap: get($v_spacing);
  }
}

.row {
  $_root: &;
  $_iconColWidth: max-content;
  $_actionsColWidth: max-content;

  display: grid;
  grid-template-areas: "header";
  grid-template-columns: 100%;
  column-gap: get($v-spacing);
  row-gap: get($v-spacing, tiny);

  &__icon {
    display: none;
    grid-area: icon;
    align-self: center;
    white-space: nowrap;
    text-align: center;
    border-radius: 50%;
  }

  &__header {
    grid-area: header;
    align-self: center;
    overflow-wrap: anywhere;
  }

  &__detail {
    display: none;
    grid-area: detail;
    align-self: start;
    overflow-wrap: anywhere;
  }

  &__actions {
    display: none;
    grid-area: actions;

    @include media("<sm") {
      margin-top: get($v-spacing, tiny);
    }

    @include media(">sm") {
      align-self: center;
      justify-self: center;
    }
  }

  &--withDetail {
    grid-template-areas: "header" "detail";

    #{$_root}__header {
      align-self: end;
    }

    #{$_root}__detail {
      display: initial;
    }
  }

  &--withGroupName {
    $_headerSpacing: get($v-spacing, large);
    $_headerSize: get($v-font-size-relative);
    $_borderThickness: 1px;
    $_headerOffset: calc((#{$_headerSize} - #{$_borderThickness}) / 2);

    position: relative;
    margin-top: calc((#{$_headerSize} - #{$_borderThickness}) / 2) !important; // override unstyled-list
    padding-top: $_headerSpacing !important; // override unstyled-list
    border-top: $_borderThickness solid $vc-border;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      padding-right: 1em;
      font-size: $_headerSize;
      line-height: 1;
      background-color: $vc-background;
      transform: translateY(-50%);
      content: attr(data-groupname);
    }

    .list--condensed > & {
      $_headerSpacing: get($v-spacing);

      padding-top: calc((#{$_headerSize} - #{$_borderThickness}) / 2 + #{$_headerSpacing}) !important; // override unstyled-list
    }
  }

  .list--withIcon > & {
    grid-template-areas: "icon header";
    grid-template-columns: $_iconColWidth auto;

    #{$_root}__icon {
      display: initial;
    }

    &--withDetail {
      grid-template-areas: "icon header" "icon detail";
    }
  }

  .list--withActions > & {
    grid-template-areas: "header" "actions";
    grid-template-columns: auto $_actionsColWidth;

    #{$_root}__actions {
      display: initial;
    }

    &--withDetail {
      grid-template-areas: "header" "detail" "actions";
    }

    @include media(">sm") {
      grid-template-areas: "header actions";

      &--withDetail {
        grid-template-areas: "header actions" "detail actions";
      }
    }
  }

  .list--withActions.list--withIcon > & {
    grid-template-areas: "icon header" "icon actions";
    grid-template-columns: $_iconColWidth auto;

    &--withDetail {
      grid-template-areas: "icon header" "icon detail" "icon actions";
    }

    @include media(">sm") {
      grid-template-areas: "icon header actions";
      grid-template-columns: $_iconColWidth auto $_actionsColWidth;

      &--withDetail {
        grid-template-areas: "icon header actions" "icon detail actions";
      }
    }
  }
}
