@import '../../styles/_shared.scss';

$_shadow-size: 10px;
$_content-padding: get($v-spacing, small);
$_content-size: 30px + 2*$_content-padding;

.wrapper {
  position: sticky;
  top: -1px;
  z-index: 10;

  &::before {
    position: sticky;
    top: $_content-size - $_shadow-size;
    display: block;
    height: $_shadow-size;
    background: linear-gradient(180deg, rgba(0, 0, 0, get($v-opacity, tiny)), transparent);
    transform: translateY($_shadow-size - 1px);
    content: '';
  }
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: $_content-size;
  margin-top: -$_shadow-size;
  background-color: $vc-background;
}
