@import '../../../../styles/_shared.scss';

.tabs {
  :global(.bp3-tab-list) {
    align-items: baseline;
    width: 100%;
    padding: get($v-spacing, tiny);
    overflow-x: auto;
    overflow-y: visible;
    white-space: nowrap;

    @include custom-scrollbars;
  }

  :global(.bp3-tab-panel) {
    margin-top: get($v-spacing, small);
  }
}
