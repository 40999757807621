@import '../../styles/_shared.scss';

.container {
  width: 100%;
  max-width: get($v-container-width);
  margin: 0 auto;

  &--top {
    padding-top: get($v-container-padding);
  }

  &--right {
    padding-right: get($v-container-padding);

    .antiContainer {
      margin-right: -(get($v-container-padding));
    }
  }

  &--bottom {
    padding-bottom: get($v-container-padding);
  }

  &--left {
    padding-left: get($v-container-padding);

    .antiContainer {
      margin-left: -(get($v-container-padding));
    }
  }

  &--centered {
    text-align: center;
  }

  @each $name,
    $value in $v-container-width {
    &--size-#{$name} {
      max-width: $value;
    }
  }

  @for $i from 1 through 6 {
    &>h#{$i}:first-child {
      margin-top: 0;
    }
  }

  @include media(">md") {
    &--right {
      padding-right: get($v-container-padding, large);

      .antiContainer {
        margin-right: -(get($v-container-padding, large));
      }
    }

    &--left {
      padding-left: get($v-container-padding, large);

      .antiContainer {
        margin-left: -(get($v-container-padding, large));
      }
    }
  }
}
