@function border-shadow($alpha, $color: $black, $size: 1px) {
  @return 0 0 0 $size rgba($color, $alpha);
}

@function input-transition-shadow($color: $input-shadow-color-focus, $focus: false) {
  @if $focus {
    @return
      border-shadow(1, $color, 1px),
      border-shadow(0.3, $color, 3px);
  }

  @else {
    @return
      border-shadow(0, $color, 0),
      border-shadow(0, $color, 0);
  }
}

@function dark-input-transition-shadow($color: $dark-input-shadow-color-focus, $focus: false) {
  @if $focus {
    @return
      border-shadow(1, $color, 1px),
      border-shadow(1, $color, 1px), // duplicating to minimize browser antialiasing in dark
      border-shadow(0.3, $color, 3px);
  }

  @else {
    @return
      border-shadow(0, $color, 0),
      border-shadow(0, $color, 0),
      border-shadow(0, $color, 0);
  }
}

$input-box-shadow-focus: inset 0 1px 1px rgba($black, $pt-drop-shadow-opacity) !default;

@mixin pt-input-intent($color) {
  box-shadow:
    input-transition-shadow($color),
    inset border-shadow(1, $color),
    $pt-input-box-shadow;

  &:focus {
    box-shadow:
      input-transition-shadow($color, true),
      $input-box-shadow-focus;
  }

  &[readonly] {
    box-shadow: inset 0 0 0 1px $color;
  }

  &:disabled,
  &.#{$ns}-disabled {
    box-shadow: none;
  }
}

@mixin pt-dark-input-intent($color) {
  box-shadow:
    dark-input-transition-shadow($color),
    inset border-shadow(1, $color),
    $pt-dark-input-box-shadow;

  &:focus {
    box-shadow:
      dark-input-transition-shadow($color, true),
      $pt-dark-input-box-shadow;
  }

  &[readonly] {
    box-shadow: inset 0 0 0 1px $color;
  }

  &:disabled,
  &.#{$ns}-disabled {
    box-shadow: none;
  }
}
