@import '../../styles/shared';

.wrapper {
  position: relative;
  display: block;
  object-fit: cover;
  overflow: hidden;
  color: $vc-placeholder-foreground;
  background-color: $vc-placeholder-background;
  border: 1px solid $vc-placeholder-background;
  border-radius: 50%;

  @each $name, $size in $v-avatar-size {
    &.wrapper-#{$name} {
      width: $size;
      height: $size;
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
}
