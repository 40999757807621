@import '../../../../styles/_shared.scss';

.addSection {
  margin-top: get($v-spacing);
}

.addContainer {
  display: flex;
  gap: get($v-spacing);
  align-items: flex-start;
}
