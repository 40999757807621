@function translate-media-condition($c) {
  $conditions: ("screen": "only screen",
  "print": "only print",
  "retina": "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
);

  @each $name,
    $value in $v-breakpoint {
    $_current-condition: (">#{$name}": "(min-width: #{$value + 1})",
    "<#{$name}": "(max-width: #{$value})",
  );
    $conditions: map-merge($conditions, $_current-condition);
  }

  @return map-get($conditions, $c);
}

@mixin media($args...) {
  $query: "";

  @each $arg in $args {
    $op: "";

    @if ($query !="") {
      $op: " and ";
    }

    $query: $query+$op+translate-media-condition($arg);
  }

  @media #{$query} {
    @content;
  }
}

@mixin unstyled-list {
  margin: 0;
  padding-left: 0;

  li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }
}

@mixin sentence-list($_separator: false) {
  @include unstyled-list;

  display: flex;
  flex-wrap: wrap;
  gap: get($v-spacing, tiny);
  align-items: baseline;
  margin: get($v-spacing, tiny) 0;

  li {
    display: inline;

    @if ($_separator) {
      &:not(:last-child)::after {
        margin-left: 0.1em;
        content: $_separator;
      }
    }
  }
}

@mixin custom-scrollbars($size: 4px,
$foreground-color: $light-gray1,
$background-color: rgba($light-gray1, get($v-opacity, low))) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  scrollbar-color: $foreground-color $background-color;
}
