@import '../../../styles/shared';

.row {
  display: flex;
  gap: get($v-spacing);
  align-items: center !important;
  justify-content: space-between;

  & > * {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }
}

.rowLabel {
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.amountInput {
  padding-right: 1px;
}
