@import '../../../styles/shared';

.input {
  flex-grow: 0;
  align-items: stretch;
  width: 6em;

  :global(.bp3-input-group) {
    flex-shrink: 1;
  }
}
