@import '../../../styles/shared';

.splitRow {
  display: flex;
  flex-wrap: wrap;
  gap: get($v-spacing, small) get($v-spacing);
  align-items: center;
}

.splitRowPart {
  display: flex;
  align-items: center;
}

.splitRowLabel {
  margin-right: get($v-spacing, small);
}
