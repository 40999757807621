@import '../../styles/shared.scss';

.wrapper {
  position: relative;
  z-index: 0;
  padding-bottom: 2 * get($v-container-padding);

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: block;
    width: 100%;
    max-width: get($v-container-width);
    height: 100%;
    background-color: $white;
    box-shadow: $pt-elevation-shadow-1;
    transform: translate(-50%, -50%);
    content: '';
  }

  @each $name, $value in $v-container-width {
    &--size-#{$name} {
      &::before {
        max-width: $value;
      }
    }
  }
}
