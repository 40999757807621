@import '../../styles/_shared.scss';

.singleCurrencySettings {
  display: flex;
  flex-wrap: wrap;
  gap: get($v-spacing);
  padding-left: 26px; // radio label padding

  &__primary,
  &__secondary {
    width: fit-content;

    & > *:first-child {
      margin-top: 0;
    }
  }

  &__primary {
    flex: 0 0 auto;
  }

  &__secondary {
    flex: 1 0 auto;
    min-width: 18em;
  }
}

.row {
  display: flex;
  align-items: center;
  min-height: 2.5em;
}

.exchangeRateRow {
  display: flex !important;
  gap: 1ch;
  align-items: center;
  white-space: nowrap;

  &__input {
    width: 7em;

    input {
      text-align: right;
    }

    :global(.bp3-button) {
      margin: 0 !important;
    }
  }

  &__default {
    position: static !important;
    margin: 0 !important;
    font-size: get($v-font-size-relative, decreased) !important;

    &--hidden {
      visibility: hidden;
    }
  }

  &:not(:last-child) {
    margin-bottom: get($v-spacing, small);
  }
}
