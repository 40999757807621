@import './shared';
@import './bp3-recolor';
@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/src/blueprint-datetime.scss";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "./bp3-overrides";
@import "./flags";
@import "./transition-group";

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

html,
body,
#root {
  position: relative;
  width: 100%;
  height: 100%;
}

form {
  width: 100%;
}

.text-intent-danger {
  color: $red2;
}

.text-intent-success {
  color: $green2;
}

.text-center {
  text-align: center;
}

.text-color-inherit {
  color: inherit !important;
}

@each $name,
  $value in $v-font-size-relative {
  .text-size-#{$name} {
    font-size: $value;
  }
}

// spacing helper classes (eg. mb-large)
@each $spacing-name,
  $spacing-value in $v-spacing {
  @each $direction-name,
    $direction-short-name in $v-direction-short-names {
    .m#{$direction-short-name}-#{$spacing-name} {
      margin-#{$direction-name}: $spacing-value;
    }
  }
}

@each $direction-name,
  $direction-short-name in $v-direction-short-names {
  .m#{$direction-short-name}-auto {
    margin-#{$direction-name}: auto;
  }
}

.bp3-file-upload-input {
  @each $intent,
    $color in $pt-intent-colors {
    &.#{$ns}-intent-#{$intent} {
      @include pt-input-intent($color);

      .#{$ns}-dark & {
        @include pt-dark-input-intent($color);
      }
    }
  }
}

.hidden {
  display: none !important;
}

.hidden-to-sm {
  @include media("<sm") {
    display: none !important;
  }
}

.hidden-from-sm {
  @include media(">sm") {
    display: none !important;
  }
}

.routerAnimatedSwitch {
  position: relative;
  width: 100%;
  height: 100%;

  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.bp3-breadcrumbs {
  height: auto;
  min-height: $pt-icon-size-standard;
}

h1 {
  font-size: get($v-font-size-relative, much-increased) /1em *1rem;
}

h2 {
  font-size: (get($v-font-size-relative, much-increased) +get($v-font-size-relative, increased))/2 /1em *1rem;
}

h3 {
  font-size: get($v-font-size-relative, increased) /1em *1rem;
  opacity: 70%;
}

h4 {
  font-size: (get($v-font-size-relative, increased) +get($v-font-size-relative))/2 /1em *1rem;
  opacity: 70%;
}

h5 {
  font-size: get($v-font-size-relative) /1em *1rem;
  opacity: 70%;
}

h6 {
  font-size: get($v-font-size-relative) /1em *1rem;
  opacity: 70%;
}
